<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <ul>
            <li v-for="err in serverErrors" :key="err">{{ err }}</li>
          </ul>
        </CAlert>
      </div>
      <form v-on:submit.prevent="onSubmitted">
        <div class="row">
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>RO Code</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="edited.ro_code"
                  placeholder="Enter RO Code"
                  required
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>RO Name (Eng)</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="edited.ro_name"
                  placeholder="Enter RO Name (Eng)"
                  required
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>RO Name (Bang)</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="edited.ro_name_bn"
                  placeholder="Enter RO Name (Bang)"
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Address 1</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="edited.address1"
                  placeholder="Enter Address 1"
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Address 2</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="edited.address2"
                  placeholder="Enter Address 2"
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Contact No</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="edited.contact"
                  placeholder="Enter Contact No"
              />
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Update</button>
      </form>
    </CCol>
  </CRow>
</template>

<script>

export default {
  name: "EditROCode",
  props: {
    edit: Object,
  },
  data: () => {
    return {
      toastr: "",
      showToast: false,
      edited: {
        id: 0,
        ro_code: "",
        ro_name: "",
        ro_name_bn: "",
        address1: "",
        address2: "",
        contact: "",
      },
      serverErrors: [],
      showDismissibleAlert: false,
    };
  },
  methods: {
    onSubmitted() {
      this.$store
        .dispatch("ROCode/updateROCode", this.edited)
        .then(() => {
          this.showToast = true;
          this.$toastr.s('success', "ROCode successfully updated.", "ROCode Updated");
          setTimeout(() => {
            this.$emit("onEditedROCode");
          }, 350);
        })
        .catch((err) => {
          this.showDismissibleAlert = true;
          this.serverErrors = [];
          if (err.response.data.message) {
            this.serverErrors.push(err.response.data.message);
          }

          let errors = err.response.data.errors;
          for (let field of Object.keys(errors)) {
            this.showToast = true;
            this.$toastr.e("error, " + errors[field][0] + ", Error!");
          }
        });
    },
  },
  mounted() {
    this.edited = { ...this.edit };
  },
};
</script>

<style scoped>
</style>
